<!--tinymce富文本编辑器组件-->
<template>
    <editor id="tinymce" v-model="contentHtml" :init="editorInit" :placeholder="placeholder"></editor>
</template>

<script>
    import tinymce from 'tinymce/tinymce';
    import Editor from '@tinymce/tinymce-vue';
    import 'tinymce/themes/silver/theme';
    import 'tinymce/plugins/image';
    import 'tinymce/plugins/link';
    import 'tinymce/plugins/autolink';
    import 'tinymce/plugins/contextmenu'
    import 'tinymce/plugins/colorpicker'
    import 'tinymce/plugins/textcolor'
    import 'tinymce/icons/default/icons.min.js'
    import app from '../utils/app.js';
    import {fileApi} from '../api/api';
    import axios from "axios";

    export default {
        name: "editorTinymce",
        components: {
            Editor
        },
        props: {
            content: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: '请输入内容'
            }
        },
        data() {
            return {
                editorInit: {
                    language_url: '/skins/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: '/skins/ui/oxide',
                    height: 300,
                    // plugins: 'lists image code table colorpicker textcolor wordcount',
                    plugins: 'image colorpicker link autolink',
                    menubar: false, // 隐藏最上方menu
                    paste_data_images: false, // 不允许粘贴图像
                    statusbar: false, // 隐藏编辑器底部的状态栏
                    image_dimensions: false,
                    // toolbar:
                    //     'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat',
                    toolbar: 'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright | bullist numlist | outdent indent | image link | undo redo removeformat',
                    branding: false,
                    entity_encoding: "named", // 转义
                    images_upload_handler: async (blobInfo, success, failure) => {
                        let fileFormData = new FormData();
                        fileFormData.append('uploadType', "editor"); // 其它数据需要放前面
                        fileFormData.append('file', blobInfo.blob());
                        axios({
                            method: "POST",
                            url: app.serverUrl + fileApi.upload,
                            data: fileFormData,
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                "token": app.getToken(),
                            },
                            timeout: 60000,
                            withCredentials: false /// `withCredentials` 表示跨域请求时是否需要使用凭证
                        }).then((req) => {
                            if (req.status === 200 && req.data.code === 1) {
                                success(app.serverUrl + req.data.data.file_path);
                            } else if(req.status === 200 && req.data.code === -1) {
                                failure('登录后才能上传图片~')
                            } else {
                                console.log(req);
                                failure('上传失败，请重试~')
                            }
                        }).catch(err => {
                            console.log(err);
                            failure('上传失败，请重试~')
                        })
                    },
                },
                contentHtml: ''
            }
        },
        mounted() {
            tinymce.init({});
            this.initPlaceholder();
        },
        methods: {
            // 初始化
            init() {
                this.contentHtml = this.content;
            },
            // 初始化富文本框初始化富文本框Placeholder
            initPlaceholder() {
                const that = this;
                tinymce.PluginManager.add('placeholder', function (editor) {
                    editor.on('init', function () {
                        let label = new Label();
                        onBlur();
                        tinymce.DOM.bind(label.el, 'click', onFocus);
                        editor.on('focus', onFocus);
                        editor.on('blur', onBlur);
                        editor.on('change', onBlur);
                        editor.on('setContent', onBlur);

                        function onFocus() {
                            if (!editor.settings.readonly === true) {
                                label.hide()
                            }
                            editor.execCommand('mceFocus', false)
                        }

                        function onBlur() {
                            if (editor.getContent() === '') {
                                label.show()
                            } else {
                                label.hide()
                            }
                        }
                    });
                    let Label = function () {
                        let placeholderText = editor.getElement().getAttribute('placeholder') || editor.settings.placeholder;
                        let placeholderAttrs = editor.settings.placeholderAttrs || {
                            style: {
                                position: 'absolute',
                                top: '6px',
                                left: 0,
                                color: '#cccccc',
                                padding: '.25%',
                                margin: '5px',
                                width: '80%',
                                'font-size': '12pt !important;',
                                overflow: 'hidden',
                                'white-space': 'pre-wrap'
                            }
                        };
                        let contentAreaContainer = editor.getContentAreaContainer()
                        tinymce.DOM.setStyle(contentAreaContainer, 'position', 'relative')
                        that.el = tinymce.DOM.add(contentAreaContainer, 'label', placeholderAttrs, placeholderText)
                    };
                    Label.prototype.hide = function () {
                        tinymce.DOM.setStyle(that.el, 'display', 'none')
                    };
                    Label.prototype.show = function () {
                        tinymce.DOM.setStyle(that.el, 'display', '')
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /*解决部分浏览器上层级比iview弹框低的问题*/
    .tox-tinymce-aux {
        z-index: 3000;
    }

    .tox .tox-dialog-wrap {
        z-index: 3001;
    }
</style>
