import {Message} from 'element-ui';

import axios from 'axios' // 参考地址https://www.kancloud.cn/yunye/axios/234845
import jsMd5 from 'js-md5';
import qs from 'qs';
// import de from "element-ui/src/locale/lang/de";

// const url = 'https://wwww.duolaimeng.net';
// const url = 'http://127.0.0.1:3100';
const url = location.origin.includes('duolaimeng') || location.origin.includes('jinggon') ? 'https://www.jinggon.net' : 'http://127.0.0.1:3100';
export default {
    color: '#4D96ED',
    color_xiaofu: '#7BBCB4',
    color_meiemi: '#E1002A',

    serverUrl: url,

    /**
     * 短提示
     * type: success/warning/info/error
     **/
    alert(msg = '', type = 'info', time = 2600) {
        Message({
            message: msg,
            type,
            duration: time
        });
    },

    setToken: function (token) {
        return localStorage.setItem('token', token) // 获取token
    },
    getToken: function () {
        return localStorage.getItem('token') || '';
    },
    removeToken() {
        localStorage.removeItem('token')
    },
    /**
     *   存储值
     */
    setData: function (key, value) {
        if (!value) {
            return
        }
        if (typeof value === 'object') {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            localStorage.setItem(key, value)
        }
    },
    /**
     * 获取存储的值
     * @key 需要的值对应的Key
     */
    getData(key) {
        try {
            const re = localStorage.getItem(key);
            if (re) {
                return JSON.parse(re)
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    },
    removeData(key) {
        localStorage.removeItem(key)
    },
    postJson(url, data, succCallBack, errorCallBack) {
        this.ajaxJson(url, JSON.stringify(data), 'POST', succCallBack, errorCallBack, 'application/json; charset=utf8')
    },
    ajaxJson(url, data, method, succCallBack, errorCallBack) {
        this.ajax(url, data, method, succCallBack, errorCallBack, 'application/json; charset=utf8')
    },
    get: (url, data) => {
        let reqParam = '';
        if (data) {
            for (let key in data) {
                const item = data[key];
                if (!item) {
                    continue;
                }
                reqParam += key + '=' + item + '&'
            }
            reqParam = reqParam.substr(0, reqParam.length - 1)
        }
        return this.ajax(url + '?' + reqParam, {}, 'GET')
    },
    ajax: function (url, data = {}, method = "POST") {
        const reqUrl = this.serverUrl + url,
            token = this.getToken();
        data.t = new Date().getTime();
        data.rp = this.f(data.t);
        return new Promise((resolve) => {
            axios({
                method: method,
                url: reqUrl,
                data: qs.stringify(data),
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf8',
                    "token": token
                },
                // requestHeader: {'Content-Type': 'application/x-www-form-urlencoded; charset=utf8'},
                timeout: 30000,
                withCredentials: false /// `withCredentials` 表示跨域请求时是否需要使用凭证
            }).then((req) => {
                let result = {};
                switch (req.data.code) {
                    case 1:
                    case 2:
                        result = req.data;
                        break;
                    case -1:
                        result = {code: -1, msg: '登录过期，请重新登录~'};
                        const s_t = setTimeout(() => {
                            clearTimeout(s_t);
                            this.removeToken();
                            window.location.href = '/login';
                        }, 2000);
                        break;
                    default:
                        result = {code: 3, msg: '请求出错了，请稍后重试~'};
                        console.error(req, reqUrl, data);
                        break;
                }
                resolve(result);
            }).catch((err) => {
                console.log(err);
                resolve({code: 3, msg: '请求出错了，请重试~'})
                // this.alert('网络错误，请检查网络', 'error', 2)
            })
        })
    },

    // 回到顶部
    scrollTop() {
        window.scrollTo(0, 0);
    },

    // md5加密
    md5(p) {
        return jsMd5(p);
    },

    // 生成请求加密参数
    f(t){
        const token = this.getToken() || '';
        return this.md5(this.md5(t + 2020 + '') + token);
    },

    // 分类的类型静态数据
    bugTList: [
        {name: 'bug文章', value: '1'},
        {name: '问题', value: '2'},
    ],

    // 经验值转换
    expList: [
        {level: 0, exp_num: 0},
        {level: 1, exp_num: 128},
        {level: 2, exp_num: 256},
        {level: 3, exp_num: 512},
        {level: 4, exp_num: 1024},
        {level: 5, exp_num: 2048},
        {level: 6, exp_num: 4096},
        {level: 7, exp_num: 8192},
        {level: 8, exp_num: 16384},
        {level: 9, exp_num: 32768},
    ],

    // 设置经验值转换
    getExpLevel(num=0) {
        const list = this.expList;
        let level = {level: 0, exp_num: 0};
        for(let i = 0; i < list.length; i++){
            if(num <= list[i].exp_num){
                level = list[i];
                break;
            }
        }
        return level;
    }

}