<!--尾部组件-->
<template>
    <div class="footer">
<!--        <div class="footer-list cursor-hand" @click="handleTap(0)">duolaimeng.net</div>-->
        <div class="footer-list">版本 v1.0.0</div>
        <div class="footer-list">微信 shen07</div>
        <a class="footer-list" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备18037743号</a>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        methods: {
            handleTap(ty) {
                switch (ty) {
                    case 0:
                        window.open('https://duolaimeng.net');
                        break;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        height: 150px;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: #666;
        background-color: #f8f8f8;

        .footer-list {
            width: 200px;
        }
    }
</style>