<template>
    <div>
        <div class="search flex align-center justify-center bg-white" :class="{'search-fixed box-border': fixed}">
            <el-input
                    clearable
                    placeholder="请输入内容"
                    v-model="value"
                    class="search-input"
                    @keyup.enter.native="handleSearch"
            >
                <i slot="suffix" class="el-input__icon el-icon-search cursor-hand" @click="handleSearch"></i>
            </el-input>
        </div>
        <div class="search" v-show="fixed"></div>
    </div>
</template>

<script>
    export default {
        name: "Search",
        computed: {
            fixed() {
                return this.$store.state.searchFixed;
            }
        },
        data() {
            return {
                value: ''
            }
        },
        methods:{
            handleSearch(){
                this.$emit('search', this.value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .search {
        height: 80px;
        width: 100%;
        transition: all .3s;

        .search-input {
            width: 60%;
            max-width: 500px;
        }
    }

    .search-fixed {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 20;
        padding: 0 10px;
    }

    @media screen and (max-width: 1000px) {
        .search {
            height: 60px;
            transition: none;

            .search-input {
                width: 100%;
            }
        }
    }
</style>