<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
    import app from './utils/app';
    import {settingApi} from "./api/api";
    export default {
        mounted() {
            this.setUserInfoLocal();
            this.bindScroll();
            this.$store.commit('setPageCss');
            this.getSetting();
        },
        methods: {
            // 设置监听
            bindScroll() {
                const _this = this;
                const w = window.screen.width <= 1000 ? 40 : 80;
                window.onscroll = function () {
                    //为了保证兼容性，这里取两个值，哪个有值取哪一个
                    //scrollTop就是触发滚轮事件时滚轮的高度
                    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    const searchFixed = _this.$store.state.searchFixed;
                    if (scrollTop > w && !searchFixed) {
                        _this.$store.commit('setSearchFixed', true);
                    } else if (scrollTop <= w && searchFixed) {
                        _this.$store.commit('setSearchFixed', false);
                    }
                }
            },
            // 第一次加载时查询是否有登录并设置用户信息
            setUserInfoLocal() {
                const token = app.getToken();
                token && this.$store.dispatch('getUserInfo');
            },

            // 获取设置的数据
            async getSetting() {
                const req = await app.ajax(settingApi.searchSetting, {});
                if (req.code === 1) {
                    this.$store.commit('setStoreState', {name: 'setting', value: req.data || []})
                } else {
                    app.alert(req.msg, 'error');
                }
            }
        }
    }
</script>

<style lang="scss">
    @import './styles/iconfont.scss';
    @import './styles/index.scss';
    /*每个页面公共css */
    $color_theme: #4D96ED;
    $font_size: 14px;
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        font-size: $font_size;
        word-break: break-all;
    }

    * {
        padding: 0;
        margin: 0;
    }

    @font-face {
        font-family: 'happy';
        src: url('./assets/font/font-happy.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    /* 特殊字体 */
    .font-happy {
        font-family: 'happy',serif;
    }

    image {
        will-change: transform;
    }

    .mar-rig {
        margin-right: 10px;
    }

    .mar-left {
        margin-left: 10px;
    }

    .pos-rel {
        position: relative;
    }

    .flex {
        display: flex;
    }

    .flex-column {
        flex-direction: column;
    }

    .flex-1 {
        flex: 1;
    }

    .inline-flex {
        display: inline-flex;
    }

    .justify-between {
        justify-content: space-between;
    }

    .justify-around {
        justify-content: space-around;
    }

    .justify-center {
        justify-content: center;
    }

    .align-center {
        align-items: center;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .color-black {
        color: #000;
    }

    /*灰色字体*/
    .color-gray {
        color: #666;
    }

    .color-red {
        color: #f00;
    }

    /*浅灰色字体*/
    .color-gray-more {
        color: #999;
    }

    /*未选中*/
    .color-no-sel {
        color: #D8D8D8;
    }

    .color-white {
        color: #fff;
    }

    /*主题颜色字体*/
    .color-theme {
        color: $color_theme;
    }

    /*大熊主题色*/
    .color-daxiong {
        color: #F3DD70;
    }

    /*胖虎主题色*/
    .color-panghu {
        color: #C88500;
    }

    /*小夫主题色*/
    .color-xiaofu {
        color: #7BBCB4;
    }

    /*静香主题色*/
    .color-jingxiang {
        color: #FE88B8;
    }

    .color-meimei {
        color: #E1002A;
    }

    .bor-theme {
        border: 1px solid $color_theme;
    }

    /*主题颜色背景*/
    .bg-theme {
        background-color: $color_theme;
    }

    /*白色背景*/
    .bg-white {
        background-color: #fff;
    }

    .bg-gray-more {
        background-color: #f5f5f5;
    }

    .bg-gray {
        background-color: #ddd;
    }

    /*蓝色渐变*/
    .bg-blue-linear-top {
        background: -moz-linear-gradient(top, #306ebc 0%, #6fabd8 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #306ebc), color-stop(100%, #6fabd8));
        background: -webkit-linear-gradient(top, #306ebc 0%, #6fabd8 100%);
        background: -o-linear-gradient(top, #306ebc 0%, #6fabd8 100%);
        background: -ms-linear-gradient(top, #306ebc 0%, #6fabd8 100%);
        background: linear-gradient(to bottom, #306ebc 0%, #6fabd8 100%);
        background-size: 100% auto;
    }

    /*蓝色渐变*/
    .bg-blue-linear-left {
        background: -moz-linear-gradient(left, #306ebc 0%, #6fabd8 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #306ebc), color-stop(100%, #6fabd8));
        /*background: -webkit-linear-gradient(top, #306ebc 0%,#6fabd8 100%);*/
        /*background: -o-linear-gradient(top, #306ebc 0%,#6fabd8 100%);*/
        /*background: -ms-linear-gradient(top, #306ebc 0%,#6fabd8 100%);*/
        /*background: linear-gradient(to bottom, #306ebc 0%,#6fabd8 100%);*/
        background-size: 100% auto;
    }

    /*蓝色渐变*/
    .bg-blue-linear-left-fan {
        background: -moz-linear-gradient(left, #6fabd8 0%, #306ebc 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, #6fabd8), color-stop(100%, #306ebc));
    }

    /*渐变提交按钮*/
    .bg-submit-linear {
        background: linear-gradient(to right, rgb(255, 207, 52), rgb(255, 145, 21));
    }

    .size-icon {
        font-size: 40px;
    }

    .size-s {
        font-size: 13px;
    }

    .box-border {
        box-sizing: border-box;
    }

    .box-shadow {
        box-shadow: 0 0 32px 0 #B6C1CE;
    }

    .bor-rad {
        border-radius: 8px;
    }

    .max-image {
        width: 100%;
        height: 100%;
    }

    /*多余省略*/
    .ellipsis {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .border-bottom {
        border-bottom: 1px solid #E6E6E6;
    }

    .font-bold {
        font-weight: bold;
    }

    .inline-block {
        display: inline-block;
    }

    .height100 {
        height: 100%;
    }

    /*鼠标手型*/
    .cursor-hand {
        cursor: pointer;
    }

    .detail-content img {
        max-width: 100%;
    }
</style>
