<!--没有数据-->
<template>
    <div class="no-data">
        <div class="iconfont icon-kong"></div>
        <div class="no-data-text">此处什么都没有~</div>
    </div>
</template>

<script>
    export default {
        name: "NoData"
    }
</script>

<style lang="scss" scoped>
    .no-data {
        padding: 30px 0;

        .iconfont {
            font-size: 80px;
            text-align: center;
            color: #999;
            margin-bottom: 10px;
        }

        .no-data-text {
            font-size: 14px;
            text-align: center;
            color: #999;
        }
    }
</style>