<!--浮动按钮-->
<template>
    <div class="to-top cursor-hand text-center" :style="{bottom: bottom, background: bg, right: right}"
         @click="handleClick">
        <i class="iconfont" :class="icon" :style="{color: color}"></i>
    </div>
</template>

<script>
    export default {
        name: "FixedBtn",
        data() {
            return {}
        },
        props: {
            // 按钮图标
            icon: {
                type: String,
                default: ''
            },
            // 背景颜色
            bg: {
                type: String,
                default: '#fff'
            },
            // 图标颜色
            color: {
                type: String,
                default: '#fff'
            },
            // 距离底部距离
            bottom: {
                type: String,
                default: '50px'
            },
            // 距离右边距离
            right: {
                type: String,
                default: '20px'
            },
        },
        methods: {
            // 点击事件
            handleClick() {
                this.$emit('tap')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .to-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: fixed;
        border-radius: 50%;
        opacity: 0.7;
        transition: bottom .5s, opacity .2s;
        z-index: 99;

        .iconfont {
            font-size: 30px;
        }
    }

    .to-top:hover {
        opacity: 1;
    }
</style>