import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import Nav from '@/components/Nav.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    // name: 'nav',
    component: Nav,
    children: [
      {
        path: '/home',
        redirect: '/'
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/Detail.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('../views/my/My.vue')
      },
      {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/admin/Admin.vue')
      },
      {
        path: '/',
        name: 'home',
        component: Home
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    component: resolve => require(['../views/About.vue'], resolve),
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
