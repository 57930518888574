<template>
    <div class="home">
        <page-search @search="handleSearch"></page-search>
        <!--        小屏幕分类-->
        <div class="type-little" :class="{'type-fixed': fixed}">
            <div class="type-l-list cursor-hand" v-for="(t_item, t_index) in typeList" :key="t_item.type_id"
                 @click="clickType(t_item.type_id)"
                 :class="{'bg-white': t_index % 2 === 0,'bg-gray-more': t_index % 2 === 1, 'bg-blue-linear-left color-white': selType === t_item.type_id}">
                {{t_item.type_name}}
            </div>
        </div>
        <div class="type-empty" v-show="fixed"></div>
        <!--大屏幕分类-->
        <div class="type">
            <div class="type-list cursor-hand text-center" v-for="(t_item, t_index) in typeList" :key="t_item.type_id"
                 @click="clickType(t_item.type_id)"
                 :class="{'bg-white': t_index % 2 === 0,'bg-gray-more': t_index % 2 === 1, 'bg-blue-linear-left color-white': selType === t_item.type_id}">
                {{t_item.type_name}}
            </div>
        </div>
        <!--        右边轮播图-->
        <el-carousel indicator-position="outside" class="ad-box" height="500px" :interval="3000" v-if="adList.length>0">
            <el-carousel-item v-for="a_item in adList" :key="a_item.ad_id">
                <div class="ad" :class="{'cursor-hand': a_item.ad_type === '1'}" @click="clickAd(a_item)">
                    <img :src="serverUrl + a_item.ad_img" alt="" class="ad-img"/>
                </div>
            </el-carousel-item>
        </el-carousel>
        <!--中间主体内容列表-->
        <div class="bug-list" v-for="item in bugList" :key="item.bug_id" @click="handleDetail(item.bug_id)">
            <div class="bug-title flex align-center">
                <div class="title-text ellipsis cursor-hand">
                    <span class="color-gray-more" v-if="item.type_name">【{{item.type_name}}】</span>
                    <span>{{item.bug_title}}</span>
                </div>

                <div class="bug-auth flex justify-between align-center">
                    <template v-if="item.user_id">
                        <span class="iconfont icon-yonghu color-gray-more"></span>
                        <span class="color-panghu auth-name">{{item.user_name}}</span>
                        <div class="info-level iconfont icon-dengji1 pos-rel color-jingxiang">
                            <span class="level-num color-white">{{getExpLevel(item.exp_num).level}}</span>
                        </div>
                    </template>
                    <span class="auth-name color-gray-more" v-else>游客</span>
                </div>
            </div>
            <div class="bug-info flex justify-between align-center color-gray-more">
                <div>
                    <span class="iconfont icon-a851"></span>
                    <span class="mar-rig">{{item.bug_hits || 0}}</span>
                    <span class="iconfont icon-huifu"></span>
                    <span class="mar-rig">{{item.total || 0}}</span>
                </div>
                <span>{{item.create_time || ""}}</span>
            </div>
        </div>
        <no-data v-show="bugList.length === 0 && !listLoading"></no-data>
        <div class="text-right" :style="{padding: `${pageCss.padding || 20}px 0`}">
            <el-pagination
                    :small="pageCss.small || false"
                    :pager-count="pageCss.pager_count || 9"
                    background
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="size"
                    :current-page.sync="page"
                    @current-change="getDataList"
            >
            </el-pagination>
        </div>
        <!--        编辑框-->
        <div class="bug-publish-title bg-white">
            <el-input placeholder="请输入标题(必填)" v-model="form.bug_title">
                <el-select v-model="form.type_id" slot="prepend" placeholder="请选择分类" no-match-text="没有分类"
                           style="width: 120px">
                    <el-option v-for="(t_item) in typeList" :key="t_item.type_id" :label="t_item.type_name"
                               :value="t_item.type_id"></el-option>
                </el-select>
            </el-input>
        </div>
        <div id="editor-box" class="bg-white">
            <editor ref="tinEditor" placeholder="请输入发布的内容..."></editor>
        </div>
        <!--        发表提交按钮-->
        <div class="detail-handle flex align-center bg-white">
            <template v-if="!isLogin">
                <div class="size-s mar-rig" v-if="isReplyAuth">
                    你当前为游客状态，是否
                    <router-link to="login" class="color-theme">登录</router-link>
                    后再发布？
                </div>
                <div class="size-s mar-rig" v-else>
                    你当前为游客状态，请
                    <router-link to="login" class="color-theme">登录</router-link>
                    后再发布。
                </div>
            </template>
            <el-button type="primary" size="small" :disabled="(!isLogin && !isReplyAuth)" :loading="publishLoading"
                       @click="handleSubmit">发布
            </el-button>
        </div>
        <div class="change-tab flex align-center">
            <span>切换为 </span>
            <el-select v-model="bug_type" placeholder="类型筛选" size="small" @change="init"
                       class="tab-select">
                <el-option v-for="(b_item, b_index) in bugTList" :key="b_index"
                           :label="b_item.name" size="mini"
                           :value="b_item.value">
                </el-option>
            </el-select>
        </div>
        <!--回到顶部-->
        <to-top :right="btnRight"></to-top>
        <!--浮动发表按钮-->
        <fixed-btn icon="icon-feiji1" :bg="`linear-gradient(to right, rgba(0,0,0,0.2), ${color})`" bottom="20px"
                   :right="btnRight + 'px'" @tap="jumpEditor"></fixed-btn>
    </div>
</template>

<script>
    // @ is an alias to /src
    import pageSearch from '@/components/Search.vue'
    import ToTop from "../components/ToTop";
    import app from '@/utils/app.js';

    import editor from '@/components/Editor.vue';
    import FixedBtn from '@/components/FixedBtn.vue';
    import NoData from "../components/NoData";
    import Vue from 'vue';
    import {Carousel, CarouselItem} from 'element-ui';
    import {ad, typeApi, bugApi, hitsApi} from '../api/api';

    Vue.use(Carousel);
    Vue.use(CarouselItem);

    export default {
        name: 'Home',
        components: {
            ToTop,
            pageSearch, FixedBtn, editor, NoData
        },
        computed: {
            fixed() {
                return this.$store.state.searchFixed;
            },
            // 分页页码相关,自适应兼容处理
            pageCss() {
                return this.$store.state.pageCss;
            },
            // 右下角的浮动按钮
            btnRight() {
                const w = window.screen.width;
                return w < 500 ? 20 : 130;
            },
            // 是否游客回复权限
            isReplyAuth() {
                return (this.$store.getters.getSettingById("visitor_reply") === "1");
            },
            // 是否有登录
            isLogin() {
                return this.$store.state.isLogin;
            }
        },
        data() {
            return {
                color: app.color,
                serverUrl: app.serverUrl,
                bug_type: '1', // 当前分类的类别
                selType: "", //选中的分类id
                typeList: [], // 分类列表
                publishLoading: false,
                form: {
                    type_id: '',
                    bug_title: '' // 发表的标题
                },
                adList: [], // 广告轮播图
                page: 1,
                size: 10,
                total: 0,
                bugList: [], // 总列表
                listLoading: false,
                searchValue: "",  // 关键词搜索
                bugTList: app.bugTList,
            }
        },
        async mounted() {
            this.bug_type = this.$route.query.bug_type || '1';
            await this.init();
            await this.getAdData();
            document.title = "精工计算机技术分享";
        },
        methods: {
            // 初始化
            async init(){
                this.selType = "";
                const loading = this.$loading({
                    lock: true,
                    text: '加载数据中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(255, 255, 255, 0.5)'
                });
                await this.getDataList(false);
                await this.getTypeList();
                loading.close();
                await app.ajax(hitsApi.updatePageHits,{hits_type:'home'})
            },
            // 点击跳转到编辑框
            jumpEditor() {
                location.href = "#editor-box";
            },
            // 跳转到详情
            handleDetail(id) {
                window.open(`/detail?id=${id}`)
                // this.$router.push({name: 'detail', query: {id}});
            },
            // 查询轮播图
            async getAdData() {
                const req = await app.ajax(ad.searchAd);
                if (req.code === 1) {
                    this.adList = req.data || [];
                }
            },
            // 点击轮播图
            async clickAd(item) {
                item.ad_type === '1' && window.open(item.ad_url);
                const params = {
                    update_id: item.ad_id,
                    update_type: 'updateAdHits'
                }
                await app.ajax(hitsApi.updateHits, params);
            },
            // 获取分类
            async getTypeList() {
                const {bug_type} = this,
                    params = {
                        type_status: '1',
                        bug_type
                    };
                const req = await app.ajax(typeApi.searchType, params);
                if (req.code === 1) {
                    this.typeList = req.data || [];
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 点击分类
            clickType(id) {
                if (id === this.selType) {
                    this.selType = "";
                } else {
                    this.selType = id;
                }
                this.form.type_id = this.selType;
                this.page = 1;
                this.getDataList();
            },
            // 获取文章列表
            async getDataList(spin = true) {
                let loading;
                if (spin) {
                    loading = this.$loading({
                        lock: true,
                        text: '加载数据中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(255, 255, 255, 0.5)'
                    });
                }
                this.listLoading = true;
                const {selType, page, size, searchValue, bug_type} = this;
                let params = {
                    page,
                    size
                };
                if (selType) {
                    params.type_id = selType;
                } else {
                    params.bug_type = bug_type;
                }
                if (searchValue) {
                    params.searchValue = searchValue;
                }
                const req = await app.ajax(bugApi.searchBug, params);
                if (spin) {
                    loading.close();
                }
                this.listLoading = false;
                if (req.code === 1) {
                    this.bugList = req.data || [];
                    this.total = req.total || 0;
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 提交发布内容
            async handleSubmit() {
                const {form, publishLoading} = this,
                    bug_content = this.$refs.tinEditor.contentHtml;
                if (publishLoading) return;
                if (!form.type_id) {
                    app.alert('请选择分类', 'error');
                    return;
                }
                if (!form.bug_title) {
                    app.alert('请填写标题', 'error');
                    return;
                }
                if (!bug_content) {
                    app.alert('请填写内容', 'error');
                    return;
                }
                this.publishLoading = true;
                const params = {
                    type_id: form.type_id,
                    bug_title: form.bug_title,
                    bug_content
                }
                const req = await app.ajax(bugApi.addBug, params);
                this.publishLoading = false;
                if (req.code === 1) {
                    app.alert('发布成功', 'success');
                    this.$refs.tinEditor.init();
                    this.form.bug_title = "";
                    setTimeout(() => {
                        this.page = 1;
                        this.getDataList();
                    }, 500)
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 搜索
            handleSearch(val) {
                this.page = 1;
                this.searchValue = val;
                this.getDataList();
            },
            // 计算经验等级
            getExpLevel(num){
                return app.getExpLevel(num);
            },
        }
    }
</script>
<style lang="scss" scoped>
    .home {
        .type {
            position: fixed;
            top: 80px;
            left: 20px;
            border: 1px solid #ddd;
            overflow: auto;
            max-height: calc(100% - 100px);

            .type-list {
                height: 50px;
                line-height: 50px;
                width: 150px;
                transition: background-color .2s;
            }

            .type-list:hover {
                background-color: #eee;
            }
        }

        .type-fixed {
            position: fixed;
            top: 60px;
            width: calc(100% - 20px);
            left: 10px;
            z-index: 20;
        }

        .type-empty {
            height: 0;
        }

        .ad-box {
            width: 150px;
            position: fixed;
            top: 81px;
            right: 20px;
        }

        .el-carousel__item {
            .ad {
                width: 150px;
                height: 100%;

                .ad-img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .type-little {
        display: none;
    }

    .bug-list {
        padding: 17px 0;
        border-bottom: 1px solid #ddd;

        .bug-title {
            margin-bottom: 10px;

            .title-text {
                flex-grow: 1;
                padding-right: 10px;
                font-size: 16px;
                text-align: left;
            }

            .title-text:hover {
                color: #000;
                text-decoration: underline;
            }

            .bug-auth {
                flex-shrink: 0;
                font-size: 14px;

                .iconfont {
                    font-size: 12px;
                }

                .auth-name {
                    margin: 0 5px;
                }

                .info-level {
                    font-size: 18px;

                    .level-num {
                        position: absolute;
                        left: 50%;
                        top: 1px;
                        transform: translateX(-50%);
                        font-size: 10px;
                    }
                }
            }
        }

        .bug-info {
            font-size: 14px;
            flex-wrap: wrap;

            .iconfont {
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }

    .bug-publish-title {
        padding: 10px 0;
    }

    .detail-handle {
        padding: 20px 0;
        justify-content: flex-end;
    }

    .bug-publish-title, #editor-box, .detail-handle {
        z-index: 100;
        position: relative;
    }

    .change-tab {
        margin: 20px 0;
        justify-content: flex-end;
        .tab-select {
            width: 100px;
            margin-left: 10px;
        }
    }

    @media screen and (max-width: 1000px) {
        .home {
            .type, .ad-box {
                display: none;
            }

            .type-empty {
                height: 40px;
            }
        }
        .type-little {
            display: flex;
            white-space: nowrap;
            width: 100%;
            overflow-x: auto;

            .type-l-list {
                padding: 0 30px;
                height: 40px;
                line-height: 40px;
                flex-shrink: 0;
                transition: background-color .2s;
            }

            .type-l-list:hover {
                background-color: #eee;
            }
        }
    }

    @media screen and (max-width: 550px) {
        .publish-time {
            display: none;
        }
    }

    @media screen and (max-width: 360px) {
        .bug-list {
            .bug-info {
                font-size: 13px;
            }
        }
    }

</style>
