<!--回到顶部-->
<template>
    <el-backtop :bottom="bottom" :right="right">
        <div class="to-top bg-blue-linear-top cursor-hand text-center">
            <i class="iconfont icon-fanghuidingbu color-white"></i>
        </div>
    </el-backtop>
</template>

<script>
    import Vue from 'vue'
    import {Backtop} from 'element-ui';
    Vue.use(Backtop);
    export default {
        name: "ToTop",
        props:{
            right: {
                type: Number,
                default: 20
            },
            bottom: {
                type: Number,
                default: 75
            }
        }
    }
</script>

<style lang="scss" scoped>
    .to-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        opacity: 0.7;
        transition: bottom .5s, opacity .2s;
        z-index: 99;

        .iconfont {
            font-size: 30px;
        }
    }

    .to-top:hover {
        opacity: 1;
    }
</style>