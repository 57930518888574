import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Button, Input, Pagination, Select, Option, Loading, Table, TableColumn, Form, FormItem} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './styles/theme.scss'; // 修改主题色的文件

// 这里仅引用常用的组件，其他的请按需加载
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Form);
Vue.use(FormItem);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
