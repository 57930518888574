<template>
    <div>
        <div class="nav color-white bg-blue-linear-left">
            <div class="nav-center flex justify-between align-center">
                <router-link to="/" class="nav-title color-white">
                    <div class="iconfont icon-zhuqingting"></div>
                    <span class="font-happy">哆来梦--bug</span>
                </router-link>
                <div class="nav-user cursor-hand">
                    <router-link to="admin" class="login-text color-white mar-rig-l size-s"
                                 v-show="['1','2'].includes(userInfo.admin)">
                        管理入口
                    </router-link>
                    <i class="iconfont icon-yonghu mar-rig"></i>
                    <template v-if="isLogin">
                        <router-link to="my" class="login-text color-white mar-rig">{{userInfo.user_name}}</router-link>
                        <span class="login-text color-white" @click="handleLogout">退出</span>
                    </template>
                    <router-link to="login" v-else class="login-text color-white">登录</router-link>
                </div>
            </div>
        </div>
        <div class="page-content box-border">
            <router-view/>
        </div>
        <Footer v-if="showFooter"></Footer>
    </div>
</template>

<script>
    import {MessageBox} from 'element-ui';
    import Footer from './Footer';

    export default {
        name: 'Nav',
        components: {
            Footer
        },
        computed: {
            // 是否有登录
            isLogin() {
                return this.$store.state.isLogin
            },
            userInfo() {
                return this.$store.state.userInfo
            },
            // 是否显示底部
            showFooter() {
                const list = ["login"];
                return !list.includes(this.$route.name);
            }
        },
        methods: {
            // 点击退出
            handleLogout() {
                MessageBox.confirm('是否退出当前账号？', '', {
                    confirmButtonText: '退出',
                    cancelButtonText: '取消',
                    type: 'info'
                }).then(() => {
                    this.$store.commit('setUserInfo', {});
                    const list = ["admin"]; // 需要跳回首页的页面
                    if (list.includes(this.$route.name)) {
                        this.$router.replace({name: "home"});
                    }
                }).catch(() => {
                });
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .nav {
        .nav-center {
            width: 60%;
            height: 80px;
            margin: 0 auto;

            .nav-title {
                font-size: 30px;
                text-decoration: none;

                .iconfont {
                    display: inline-block;
                    font-size: 45px;
                    animation: logo_ani 2s linear infinite;
                }
            }

            .nav-user {
                .login-text {
                    text-decoration: none;
                    opacity: 0.7;
                }

                .login-text:hover {
                    opacity: 1;
                }

                .mar-rig-l {
                    margin-right: 20px;
                }
            }
        }
    }

    .page-content {
        width: 60%;
        margin: 0 auto;
    }

    @media screen and (max-width: 1000px) {
        .nav {
            padding: 0 10px;

            .nav-center {
                width: 100%;
                height: 40px;

                .nav-title {
                    font-size: 16px;

                    .iconfont {
                        display: inline-block;
                        font-size: 20px;
                        animation: logo_ani 2s linear infinite;
                    }
                }
            }
        }
        .page-content {
            padding: 0 10px;
            width: 100%;
        }
    }

    @keyframes logo_ani {
        from {
            transform: rotateY(0deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
</style>
