import Vue from 'vue';
import Vuex from 'vuex';
import app from '../utils/app';
import {user} from "../api/api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogin: false, // 是否有登录
        searchFixed: false, // 搜索框是否绝对定位
        pageCss: {padding: 20, small: false, pager_count: 9}, // 分页页码相关,自适应兼容处理
        userInfo: {}, // 用户数据
        setting: [], // 设置的数据
    },
    mutations: {
        setStoreState(state, payload) {
            state[payload.name] = payload.value;
        },
        setLogin(state, isLogin) {
            state.isLogin = isLogin;
        },
        setSearchFixed(state, fixed) {
            state.searchFixed = fixed;
        },
        setPageCss(state) {
            let padding = 20, small = false, pager_count = 9;
            const w = window.screen.width;
            if (w < 500) {
                padding = 10;
                small = true;
                pager_count = 5;
            }
            state.pageCss = {
                padding, small, pager_count
            }
        },
        setUserInfo(state, info) {
            state.userInfo = info;
            state.isLogin = !!(info.token);
            info.token ? app.setToken(info.token) : app.removeToken();
        }
    },
    actions: {
        async getUserInfo({commit}){
            const req = await app.ajax(user.getInfo);
            if(req.code === 1){
                commit('setUserInfo', req.data || {});
            }
        }
    },
    modules: {},
    getters:{
        getSettingById: (state) => (id) => {
            const setting = state.setting,
                sel = setting.find(s => (id === s.setting_id));
            return sel ? sel.setting_value : false
        }
    }
})
