// 放置接口地址
export const user = {
    addUser: '/api/user/addUser', // 用户注册
    login: '/api/user/login', // 用户登录
    getInfo: '/api/user/getInfo', // 根据token查询用户信息
    updateInfo: '/api/user/updateInfo', // 修改用户信息
    updatePassword: '/api/user/updatePassword', // 修改密码
};
export const ad = {
    addAd: '/api/ad/addAd', // 添加轮播图
    updateAd: '/api/ad/updateAd', // 修改轮播图
    searchAd: '/api/ad/searchAd', // 查询轮播图
    delAd: '/api/ad/delAd', // 删除轮播图
};
// 分类
export const typeApi = {
    addType: '/api/type/addType', // 添加
    updateType: '/api/type/updateType', // 修改
    searchType: '/api/type/searchType', // 查询
    delType: '/api/type/delType', // 删除
};
// 设置
export const settingApi = {
    searchSetting: '/api/setting/searchSetting', // 查询
    updateSetting: '/api/setting/updateSetting', // 修改
};
// bug
export const bugApi = {
    addBug: '/api/bug/addBug', // 添加
    searchBug: '/api/bug/searchBug', // 查询
    searchDetail: '/api/bug/searchDetail', // 查询详情
    searchReplyList: '/api/bug/searchReplyList', // 查询回复列表
    searchReplyItem: '/api/bug/searchReplyItem', // 查询子回复列表
    addReply: '/api/bug/addReply', // 添加回复
    updateBug: '/api/bug/updateBug', // 删除bug
    updateReply: '/api/bug/updateReply', // 删除回复
    searchWasteList: '/api/bug/searchWasteList', // 回收站列表
    handleWaste: '/api/bug/handleWaste', // 回收站列表
    searchMyList: '/api/bug/searchMyList', // 我的发布
};
// 收藏
export const collectApi = {
    searchIsCollect: '/api/collect/searchIsCollect',  // 查询是否收藏
    delCollect: '/api/collect/delCollect',  // 取消收藏
    addCollect: '/api/collect/addCollect',  // 收藏
    searchList: '/api/collect/searchList',  // 收藏列表
};
// 点击率
export const hitsApi = {
    updateHits: '/api/hits/updateHits',  // 更新点击率
    updatePageHits: '/api/hits/updatePageHits',  // 更新页面总点击率
};

// 上传
export const fileApi = {
    upload: '/api/file/upload',  // 上传
};


